import React, { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import styled from "styled-components";
import { HeaderIcon } from "../../assets/HeaderIcon";

function Popup() {
  const [links, setLinks] = useState<{ name: string; url: string }[]>([]);

  const getEnvironment = (): "us" | "uk" | "development" => {
    const apiPath = process.env.REACT_APP_API_PATH || "";
    if (apiPath.includes("us")) {
      return "development";
    } else if (apiPath.includes("development")) {
      return "development";
    } else {
      return "uk";
    }
  };

  const generateLinks = () => {
    const environment = getEnvironment();
    let envLinks: { name: string; url: string }[] = [];

    switch (environment) {
      case "us":
        envLinks = [
          { name: "TOOLBOX", url: "https://us.toolbox.bjshomedelivery.com/" },
          { name: "EPOD", url: "https://us.epod.bjshomedelivery.com/login" },
          { name: "ROUTING", url: "https://us.routing.bjshomedelivery.com" },
        ];
        break;
      case "development":
        envLinks = [
          { name: "TOOLBOX", url: "https://toolbox.bjshomedelivery.com/" },
          { name: "EPOD", url: "https://epod.bjshomedelivery.com/login" },
          { name: "ROUTING", url: "https://routing.bjshomedelivery.com" },
        ];
        break;
      default:
        envLinks = [{ name: "No links available", url: "" }];
        break;
    }

    setLinks(envLinks);
  };

  useEffect(() => {
    generateLinks();
  }, []);

  const content = (
    <div>
      {links.map((link, index) => (
        <p key={index}>
          {link?.url ? (
            <a href={link?.url} target="_blank" rel="noopener noreferrer">
              {link?.name}
            </a>
          ) : (
            link.name
          )}
        </p>
      ))}
    </div>
  );

  return (
    <Popover content={content} title="Links">
      <Btn type="primary">
        <HeaderIcon />
      </Btn>
    </Popover>
  );
}

const Btn = styled(Button)`
  background-color: white !important;
  color: #363a30;
  &:hover {
    background-color: white !important;
    color: #feba36;
    svg {
      path {
        fill: #feba36;
      }
    }
  }
`;

export default Popup;
