import React, { useEffect, useState } from "react";
import {
  CloseIcon,
  RadioButtonGroupHorizontal,
  StandardOptionsType,
} from "@bjsdistribution/dms-component-library";
import {
  Bottom,
  Container,
  Content,
  CustomizedRadio,
  Left,
  StyledLink,
  MainDiv,
  Right,
  Top,
} from "./popupStyles";
import { snoozeData } from "./utils";
import ReminderIcons from "./ReminderIcons";
import {
  useIsModalOpen,
  useOverdueReminder,
  useReminderId,
} from "../../store/store";
import ViewReminderModal from "./ViewReminder";
import Loader from "../../common/loader/Loader";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const PopupOverdue = (props: any) => {
  const subtitle = "Overdue!";
  const [selectedSnooze, setSnoozeTime] = useState(0);
  const [isModalOpen, setIsModalOpen] = useIsModalOpen();
  const [, setUseRefId] = useReminderId();
  const [snoozedReminders, setSnoozedReminders] = useState<any[]>([]);
  const [highPriorityReminders, setHighPriorityReminders] = useState<any[]>([]);
  const [isShow, setIsShow] = useState(true);
  const [overDueCount] = useOverdueReminder();

  const snoozeOptions: StandardOptionsType[] = snoozeData?.map(
    (option: any) => ({
      label: option.value,
      value: option.value,
    })
  );

  const handleOpen = (Id: any) => {
    setUseRefId(Id);
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsShow(false);
  };

  useEffect(() => {
    const today = format(new Date(), "yyyy-MM-dd");

    const filteredReminders = props?.reminders?.filter((reminder: any) =>
      reminder?.due?.startsWith(today)
    );

    if (filteredReminders?.length > 0) {
      const latestReminder = filteredReminders?.sort(
        (a: any, b: any) =>
          new Date(b.due).getTime() - new Date(a.due).getTime()
      )[0];

      setHighPriorityReminders([latestReminder]);
    } else {
      setHighPriorityReminders([]);
    }
  }, [props.reminders]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();

      const remindersToReappear = snoozedReminders?.filter(
        (reminder) => now >= reminder.snoozeUntil
      );

      if (remindersToReappear?.length > 0) {
        const latestReminderToReappear = remindersToReappear.sort(
          (a, b) =>
            new Date(b?.snoozeUntil).getTime() -
            new Date(a?.snoozeUntil).getTime()
        )[0];

        setHighPriorityReminders([latestReminderToReappear]);

        setSnoozedReminders((prev) =>
          prev.filter(
            (reminder) => reminder?.id !== latestReminderToReappear?.id
          )
        );
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [snoozedReminders]);

  // Handle snooze time for reminders
  const handleSnoozeChange = (event: any, reminderId: number) => {
    const newValue = event.target.value;
    setSnoozeTime(newValue);

    const snoozeDurationInMinutes = parseInt(newValue, 10);
    const snoozeUntil = new Date(
      Date.now() + snoozeDurationInMinutes * 60 * 1000
    );

    setHighPriorityReminders((prev: any) =>
      prev.filter((reminder: any) => reminder.id !== reminderId)
    );

    const snoozedReminder = props?.reminders?.find(
      (reminder: any) => reminder.id === reminderId
    );

    if (snoozedReminder) {
      setSnoozedReminders((prev) => [
        ...prev,
        { ...snoozedReminder, snoozeUntil },
      ]);
    }
    setSnoozeTime(0);
  };

  return (
    <>
      {highPriorityReminders?.length > 0 ? (
        <>
          {isShow ? (
            <>
              {props.loading && <Loader />}
              {highPriorityReminders?.map((reminder: any) => (
                <MainDiv key={reminder.id}>
                  <Container>
                    <Content>
                      <Top>
                        <div className="left">
                          <ReminderIcons />
                          <Left>
                            <div className="heading">
                              {overDueCount?.length > 1
                                ? "Reminders for you"
                                : "Reminder for you"}
                              <Link
                                className="circleRem"
                                to="/orders/all-reminder"
                              >
                                {" "}
                                {overDueCount?.length > 1 ? (
                                  <>+{overDueCount?.length - 1}</>
                                ) : null}
                              </Link>
                            </div>
                            <div className="subheading">
                              <StyledLink
                                onClick={() => handleOpen(reminder.id)}
                              >
                                {reminder?.reference || "NA"}
                              </StyledLink>
                              {subtitle}
                            </div>
                          </Left>
                        </div>
                        <Right onClick={onClose}>
                          <CloseIcon />
                        </Right>
                      </Top>
                      <hr
                        style={{
                          width: "100%",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      ></hr>
                      <Bottom>
                        <div className="subheading"> Snooze : </div>
                        <CustomizedRadio>
                          <RadioButtonGroupHorizontal
                            heading=""
                            options={snoozeOptions}
                            onChange={(e) => handleSnoozeChange(e, reminder.id)}
                            direction="horizontal"
                            selectedValue={selectedSnooze}
                            required={false}
                          />
                        </CustomizedRadio>
                      </Bottom>
                    </Content>
                  </Container>
                </MainDiv>
              ))}
              {isModalOpen && <ViewReminderModal />}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default PopupOverdue;
