export const menuOptions = [
    {
      label: 'Consignment',
      value: 'consignment',
    },
    {
      label: 'Postcode',
      value: 'postcode',
    },
    {
      label: 'Order Number',
      value: 'ordernumber',
    },
    {
        label: 'Recepient',
        value: 'recipient',
      },
  ];