import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReminderIcon from "./ReminderIcon";
import { useCountReminder } from "../store/store";
// import NotificationIcon from "./NotificationIcon";

const Notifications = () => {
  const [count] = useCountReminder();

  return (
    <Wrapper>
      <NotificationWrapper>
        <Link to="/orders/all-reminder">
          <ReminderIcon />
          <span className="reminder">{count?.length}</span>
        </Link>
      </NotificationWrapper>
      {/* <NotificationWrapper>
        <NotificationIcon />
        <span>{0}</span>
      </NotificationWrapper> */}
    </Wrapper>
  );
};

export default Notifications;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const NotificationWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  span {
    display: block;
    position: absolute;
    min-width: 20px;
    height: 20px;
    background: #dc3545;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    top: -10px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    cursor: pointer;
  }
`;
