import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { menuOptions } from "../../helper";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Props {
  className?: string;
  inputType?: string;
}

export const Search = ({
  className,
  inputType = "text",
}: Props): JSX.Element => {
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>(
    undefined
  );
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchType = searchParams.get("search_type");
  const searchQuery = searchParams.get("search_query");

  const handleSelectChange = (value: any) => {
    setSelectedLabel(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleDoSearch = () => {
    if (selectedLabel && searchText) {
      navigate(
        `/orders?search_type=${selectedLabel}&search_query=${searchText}`
      );
    } else {
      console.log("Please select an option and enter search text");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleDoSearch();
    }
  };

  useEffect(() => {
    if (searchType) {
      setSelectedLabel(searchType);
    } else {
      setSelectedLabel(undefined);
    }

    if (searchQuery) {
      setSearchText(searchQuery);
    } else {
      setSearchText("");
    }
  }, [searchType, searchQuery]);

  return (
    <StyledSearch className={className}>
      <SelectDropdown
        value={selectedLabel}
        onChange={handleSelectChange}
        className="select-dropdown"
        placeholder="Select"
        options={menuOptions}
        dropdownStyle={{ width: "150px", zIndex: 10000 }}
      />
      <div className="input-container">
        <input
          className="search-input"
          placeholder="Search..."
          type={inputType}
          value={searchText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {searchText && <SearchIcon onClick={handleDoSearch} />}
      </div>
    </StyledSearch>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  inputType: PropTypes.string,
};

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f2f2;
  height: 44px;
  padding: 0 10px;
  width: 417px;
  margin-left: 310px;
  margin-top: -60px;
  border-radius: 30px;
  border: 1px solid #e2e0db;
  background: #fff;

  .input-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    outline: none;
  }

  & .ant-select {
    width: max-content;
    margin-right: 10px;
  }

  & .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  & .search-input {
    background: transparent;
    border: none;
    font-family: var(--body-captions-font-family);
    font-size: var(--body-captions-font-size);
    padding-left: 10px;
    padding-right: 30px;
    width: 100%;
    outline: none;
  }
`;

const SelectDropdown = styled(Select)`
  .ant-select-selector {
    border-radius: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
  }

  .ant-select-dropdown {
    border-radius: 10px;
  }
`;

const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  right: 10px;
  color: #bfbfbf;
  font-size: 18px;
  cursor: pointer;
`;

export default Search;
