import {
  getBaseEndpoint,
  useCustomersApiLoading,
} from "@bjsdistribution/dms-component-library";
import { notification } from "antd";
import { useToken } from "../../store/store";

function useFetchCustomers() {
  const [ssoToken] = useToken();
  const [, setCustomersApiLoading] = useCustomersApiLoading();
  const perform = async () => {
    const endpoint = `${getBaseEndpoint()}/customers/`;
    setCustomersApiLoading(true);
    try {
      const headers = {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ssoToken}`,
        "x-pagination": JSON.stringify({
          is_pagination: false,
        }),
      };
      const response = await fetch(endpoint, { method: "GET", headers });
      if (!response.ok) {
        notification.error({
          message: "Error",
          description: "Failed to fetch customers",
        });
        return [];
      }
      const json = await response.json();
      return json.data;
    } catch (err) {
      notification.error({
        message: "Error",
        description:
          `${err}` || "An error has occurred. Please try again later.",
      });
      return [];
    }
  };
  return perform;
}

export default useFetchCustomers;
