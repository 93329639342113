// App.js
import React, { useEffect } from "react";
import AppRouter from "./AppRouter";
import { GlobalStyles } from "./style/global";
import { useToken } from "./store/store";
import useFetchCustomers from "./layout/hooks/useFetchCustomers";
import { getCache, setCache } from "./common/utils";
import { LOCAL_STORAGE_CUSTOMERS_DATA_KEY } from "./common/constants";
import { useCustomersApiLoading } from "@bjsdistribution/dms-component-library";

function App() {
  const [, setCustomersApiLoading] = useCustomersApiLoading();

  const [token] = useToken();
  const fetchCustomers = useFetchCustomers();

  const fetchAndSetCustomers = async () => {
    const result = await fetchCustomers();
    setCache(LOCAL_STORAGE_CUSTOMERS_DATA_KEY, result);
    setCustomersApiLoading(false);
  };

  useEffect(() => {
    // call fetch customers api
    if (token) {
      const customerData = getCache(LOCAL_STORAGE_CUSTOMERS_DATA_KEY);
      if (!customerData?.length) {
        fetchAndSetCustomers();
      }
    }
  }, [token]);

  return (
    <>
      <GlobalStyles />
      <AppRouter />
    </>
  );
}

export default App;
