const ID_TOKEN = "id_token";

const LOCAL_STORAGE_CUSTOMERS_DATA_KEY = "customers";

const LOCAL_STORAGE_CUSTOMERS_API_LOADING_KEY = "customersLoading";

export {
  ID_TOKEN,
  LOCAL_STORAGE_CUSTOMERS_DATA_KEY,
  LOCAL_STORAGE_CUSTOMERS_API_LOADING_KEY,
};
